body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: #e6ecf0;
}
.modal {
  position: static !important;
}
.modal .close {
  background-color: black;
  border-radius: 50px;
}
.modal .close.edit-close {
  position: static !important;
  float: right;
  margin-top: -2.5rem;
  margin-right: -2.5rem;
}
.modal .close.mosaic-close {
  position: static !important;
  float: right;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
}
.modal.new-post {
  padding: 15px;
  background: #e6ecf0;
}
.modal.new-post .header {
  /*color:@color-pluralis !important;	*/
  margin-top: 3px;
  /*pour voir les bords arrondis du contenant*/
  border-top-left-radius: 0.28571429rem;
  border-top-right-radius: 0.28571429rem;
}
.modal.new-post .content {
  border-bottom-left-radius: 0.28571429rem;
  border-bottom-right-radius: 0.28571429rem;
}
.modal.new-post .button {
  background-color: #fff !important;
  color: #648bb6 !important;
}
.modal.new-post .button:hover,
.modal.new-post .button:active,
.modal.new-post .button:focus {
  color: #648bb6 !important;
}
.modal.new-post .actions {
  background: transparent;
  border: none;
}
.modal.confirm-modal .content {
  text-align: center;
}
.orange {
  color: orange;
}
.red {
  font-weight: bold;
  color: red;
}
#app .App,
.new-post .App {
  margin-top: 15px;
  /*text-align: center;*/
}
#app .color-com,
.new-post .color-com {
  color: #94c11f !important;
}
#app .bg-com,
.new-post .bg-com {
  background-color: #94c11f !important;
}
#app .color-dev,
.new-post .color-dev {
  color: #f9b233 !important;
}
#app .bg-dev,
.new-post .bg-dev {
  background-color: #f9b233 !important;
}
#app .color-pat,
.new-post .color-pat {
  color: #2fac66 !important;
}
#app .bg-pat,
.new-post .bg-pat {
  background-color: #2fac66 !important;
}
#app .color-hab,
.new-post .color-hab {
  color: #4bc2f1 !important;
}
#app .bg-hab,
.new-post .bg-hab {
  background-color: #4bc2f1 !important;
}
#app .color-rh,
.new-post .color-rh {
  color: #344f9e !important;
}
#app .bg-rh,
.new-post .bg-rh {
  background-color: #344f9e !important;
}
#app .color-sup,
.new-post .color-sup {
  color: #986fae !important;
}
#app .bg-sup,
.new-post .bg-sup {
  background-color: #986fae !important;
}
#app .color-dir,
.new-post .color-dir {
  color: #991636 !important;
}
#app .bg-dir,
.new-post .bg-dir {
  background-color: #991636 !important;
}
#app .color-isa,
.new-post .color-isa {
  color: #555542 !important;
}
#app .bg-isa,
.new-post .bg-isa,
#app .bg-isalis,
.new-post .bg-isalis {
  background-color: #555542 !important;
}
#app .color-pluralis,
.new-post .color-pluralis {
  color: #648bb6 !important;
}
#app .bg-pluralis,
.new-post .bg-pluralis {
  background-color: #648bb6 !important;
}
#app .bg-white,
.new-post .bg-white {
  background-color: #fff;
}
#app .ui.grid > .row > .column,
.new-post .ui.grid > .row > .column {
  padding-right: 0rem;
}
#app .App-header,
.new-post .App-header {
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (max-width: 767px) {
  #app .App-header,
  .new-post .App-header {
    margin-left: -1em !important;
    margin-right: 0em !important;
    max-width: none !important;
  }
}
#app .App-header .App-logo,
.new-post .App-header .App-logo {
  margin-top: 2px;
  /*	position: absolute;
			left: 7%;*/
  text-align: center;
}
#app .App-header .App-logo .ui.mini.image,
.new-post .App-header .App-logo .ui.mini.image {
  margin: 5px auto;
  width: 45px;
}
#app .App-header .header-title,
.new-post .App-header .header-title {
  float: left;
  margin-top: 12px;
  font-weight: 500;
  font-size: 17px;
  position: absolute;
  left: 55px;
  color: #648bb6;
}
@media (max-width: 415px) {
  #app .App-header .menu-container,
  .new-post .App-header .menu-container {
    padding-left: 2px;
  }
}
#app .App-header .menu.menu-services,
.new-post .App-header .menu.menu-services {
  border: none;
  box-shadow: none;
}
@media (max-width: 736px) {
  #app .App-header .menu .item,
  .new-post .App-header .menu .item {
    padding: 0.6em 5px 0.2em;
  }
  #app .App-header .menu .item .ui.mini.image,
  .new-post .App-header .menu .item .ui.mini.image {
    width: 7vw;
  }
}
#app .App-header .menu .item .label,
.new-post .App-header .menu .item .label {
  margin-left: 0;
}
#app .App-header .menu .item:last-child,
.new-post .App-header .menu .item:last-child,
#app .App-header .menu .item:first-child,
.new-post .App-header .menu .item:first-child {
  border-radius: 0% !important;
}
@media (max-width: 768px) {
  #app .App-header .menu .item:first-child,
  .new-post .App-header .menu .item:first-child {
    margin-left: 0px !important;
  }
}
@media (max-width: 440px) {
  #app .App-header .menu .item.burger,
  .new-post .App-header .menu .item.burger {
    right: 0px;
    font-size: 0.78571429rem;
  }
}
@media (max-width: 320px) {
  #app .App-header .menu .item.burger,
  .new-post .App-header .menu .item.burger {
    margin-top: 2px;
    font-size: 0.68rem;
  }
}
#app .App-header .menu .item.back-top,
.new-post .App-header .menu .item.back-top {
  position: fixed;
  z-index: 1100;
  bottom: 10px;
  right: 2px;
}
#app .App-header .menu .item.back-top .button,
.new-post .App-header .menu .item.back-top .button {
  background-color: #ffffff9e !important;
}
#app .App-header .menu .item.active,
.new-post .App-header .menu .item.active {
  background: #e6ecf0;
}
#app .App-header .row,
.new-post .App-header .row {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
#app .App-header.mobile-secondary-menu,
.new-post .App-header.mobile-secondary-menu {
  margin-top: 15px;
  /*	z-index: 101;*/
  /*	width: 100%!important;*/
}
#app .App-header.mobile-secondary-menu .row,
.new-post .App-header.mobile-secondary-menu .row {
  padding-top: 0.6rem !important;
  padding-bottom: 0.5rem !important;
  display: inline-block;
  text-align: center;
  color: #fff;
  height: 40px;
}
#app .App-header.mobile-secondary-menu .row .header,
.new-post .App-header.mobile-secondary-menu .row .header,
#app .App-header.mobile-secondary-menu .row a,
.new-post .App-header.mobile-secondary-menu .row a {
  color: #fff;
}
#app .header-extra,
.new-post .header-extra {
  /*	position: absolute;
		right: 10%;
		top: 10%;
		*/
  text-align: center;
  margin: auto;
  font-weight: 500;
  color: dimgrey;
  float: right;
}
#app .main-layout,
.new-post .main-layout {
  margin-top: 15px;
}
@media only screen and (max-width: 767px) {
  #app .main-layout,
  .new-post .main-layout {
    margin-top: 10px;
    margin-left: -1em !important;
    margin-right: 0em !important;
    max-width: none !important;
  }
}
@media only screen and (max-width: 490px) {
  #app .main-layout,
  .new-post .main-layout {
    /*	margin-top: 50px;*/
  }
}
#app .button.add-post,
.new-post .button.add-post {
  background-color: #fff !important;
  margin-top: -5px;
}
#app .center-content,
.new-post .center-content {
  margin-top: 5px;
  margin-bottom: 15px;
  /*	padding:15px !important;*/
}
#app .center-content .item,
.new-post .center-content .item {
  padding: 15px 15px 35px 15px !important;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
}
@media (max-width: 736px) {
  #app .center-content .item,
  .new-post .center-content .item {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
#app .center-content .item.pending,
.new-post .center-content .item.pending {
  border: 3px solid #f2711c;
  /*@media  (max-width: 768px) and (min-width: 992px)*/
}
#app .center-content .item.pending .content,
.new-post .center-content .item.pending .content {
  opacity: 0.5;
}
#app .center-content .item.pending .content.edit,
.new-post .center-content .item.pending .content.edit {
  opacity: 1;
}
@media (max-width: 992px) {
  #app .center-content .item.pending,
  .new-post .center-content .item.pending {
    display: none;
  }
}
#app .center-content .item .header,
.new-post .center-content .item .header {
  display: block;
  margin: 0;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: 700;
}
#app .center-content .item .header .date,
.new-post .center-content .item .header .date {
  font-size: 12px;
  color: grey;
  font-style: italic;
  font-weight: normal;
}
#app .center-content .item .header .date::before,
.new-post .center-content .item .header .date::before {
  margin: 0 10px;
  content: "\2022";
}
#app .center-content .item .description,
.new-post .center-content .item .description {
  color: #14171a;
  text-align: left;
  margin-right: 2px;
  word-break: break-word;
}
#app .center-content .item .toolbar,
.new-post .center-content .item .toolbar {
  margin-top: -10px;
  margin-right: -15px !important;
  height: 15px;
}
#app .center-content .item .toolbar .button,
.new-post .center-content .item .toolbar .button {
  background-color: #fff !important;
  color: #648bb6 !important;
}
#app .center-content .item .toolbar .button.check,
.new-post .center-content .item .toolbar .button.check {
  margin-left: 10px;
}
#app .center-content .item .toolbar .button.info,
.new-post .center-content .item .toolbar .button.info {
  font-size: 0.678571429rem;
  background-color: #f2711c !important;
  color: #fff !important	;
}
#app .center-content .item .toolbar .button.help,
.new-post .center-content .item .toolbar .button.help {
  font-size: 0.678571429rem;
  background-color: #21ba45 !important;
  color: #fff !important	;
}
@media (max-width: 1024px) {
  #app .center-content .item .toolbar,
  .new-post .center-content .item .toolbar {
    display: none;
  }
}
#app .center-content .item img.avatar,
.new-post .center-content .item img.avatar {
  width: 50px !important;
  float: left;
}
@media (max-width: 736px) {
  #app .center-content .item img.avatar,
  .new-post .center-content .item img.avatar {
    width: 30px !important;
  }
}
#app .center-content .item .content,
.new-post .center-content .item .content {
  /*display:table-cell;*/
  /* ie n'aime pas*/
  display: block;
  margin-left: 60px;
  margin-right: 20px;
  line-height: 20px;
}
@media (max-width: 736px) {
  #app .center-content .item .content,
  .new-post .center-content .item .content {
    margin-left: 30px;
    margin-right: 0px;
  }
}
#app .center-content .item .mosaic,
.new-post .center-content .item .mosaic {
  margin-top: 1px;
  /*margin-bottom:10px;*/
  margin-right: 1px;
  margin-left: 0px;
}
#app .center-content .item .mosaic .cover,
.new-post .center-content .item .mosaic .cover {
  /*object-fit: cover;*/
  background-position: center;
  background-size: cover;
}
#app .center-content .item .mosaic .column,
.new-post .center-content .item .mosaic .column {
  padding: 0;
}
#app .center-content .item .mosaic.mosaic-1 .first,
.new-post .center-content .item .mosaic.mosaic-1 .first {
  height: 330px;
  border-right: 2px solid white;
  border-radius: 12px;
}
@media only screen and (max-width: 767px) {
  #app .center-content .item .mosaic.mosaic-1 .first,
  .new-post .center-content .item .mosaic.mosaic-1 .first {
    height: 150px;
  }
}
#app .center-content .item .mosaic.mosaic-2 .first,
.new-post .center-content .item .mosaic.mosaic-2 .first {
  height: 330px;
  border-right: 2px solid white;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
@media only screen and (max-width: 767px) {
  #app .center-content .item .mosaic.mosaic-2 .first,
  .new-post .center-content .item .mosaic.mosaic-2 .first {
    height: 150px;
  }
}
#app .center-content .item .mosaic.mosaic-2 .second,
.new-post .center-content .item .mosaic.mosaic-2 .second {
  border-bottom: 2px solid white;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
#app .center-content .item .mosaic.mosaic-3 .first,
.new-post .center-content .item .mosaic.mosaic-3 .first {
  height: 330px;
  border-right: 2px solid white;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
@media only screen and (max-width: 767px) {
  #app .center-content .item .mosaic.mosaic-3 .first,
  .new-post .center-content .item .mosaic.mosaic-3 .first {
    height: 150px;
  }
}
#app .center-content .item .mosaic.mosaic-3 .second,
.new-post .center-content .item .mosaic.mosaic-3 .second {
  border-bottom: 2px solid white;
  border-top-right-radius: 12px;
}
#app .center-content .item .mosaic.mosaic-3 .third,
.new-post .center-content .item .mosaic.mosaic-3 .third {
  border-bottom-right-radius: 12px;
}
#app .center-content .item .mosaic.mosaic-4 .first,
.new-post .center-content .item .mosaic.mosaic-4 .first {
  height: 330px;
  border-right: 2px solid white;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
@media only screen and (max-width: 767px) {
  #app .center-content .item .mosaic.mosaic-4 .first,
  .new-post .center-content .item .mosaic.mosaic-4 .first {
    height: 150px;
  }
}
#app .center-content .item .mosaic.mosaic-4 .second,
.new-post .center-content .item .mosaic.mosaic-4 .second {
  border-bottom: 2px solid white;
  border-top-right-radius: 12px;
}
#app .center-content .item .mosaic.mosaic-4 .fourth,
.new-post .center-content .item .mosaic.mosaic-4 .fourth {
  border-top: 2px solid white;
  border-bottom-right-radius: 12px;
}
#app .center-content .item .video,
.new-post .center-content .item .video {
  margin-top: 1rem;
  border-radius: 12px;
}
#app .center-content .item .mobile-card-logo .pluralis-logo,
.new-post .center-content .item .mobile-card-logo .pluralis-logo {
  margin: -20px auto 0;
  border: 1px solid #d4d4d55c;
  padding: 5px;
  background-color: #fff;
}
#app .left-column .card .header,
.new-post .left-column .card .header {
  color: #648bb6;
}
#app .right-column .menu,
.new-post .right-column .menu {
  width: auto;
}
#app .right-column .menu .item.active,
.new-post .right-column .menu .item.active {
  background-color: #648bb6;
  color: white;
}
#app .mobile-menu .item.active,
.new-post .mobile-menu .item.active {
  background-color: #648bb6;
  color: white;
}
.ScrollUpButton__Container {
  right: 5px !important;
  background-color: #ffffffcc !important;
  border-radius: 50px;
  border: 1px solid #648bb6 !important;
  fill: #648bb6 !important;
  background-color: #648bb6 !important;
  fill: #fff !important;
}

